  .jarallax {
    position: relative;
    z-index: 0;
    padding: 100px 0 80px;
}
.jarallax {
  .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  h1 {
    color: #fff;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px #000;
    font-size: 38px;
  }
  .top-overlay {
    margin-top: 50px;
  }
}

#startchange {
  top: 220px;
  position: absolute; 
}

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000']{
  transition-duration: 1000ms;
  }