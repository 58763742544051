.orangePipNav {
  position: fixed;
  width: 100%;
  z-index: 99999;
  padding: 10px 0;
  background-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: transparent;
  transition: all 0.8s ease;

  ul {
    text-align: right;
    padding-top: rem-calc(8);
  }

  .accordion-menu a {
    display:inline-block;
  }
  .accordion-menu a, .dropdown.menu a {
    color: $white;
    padding: 8px 2px 12px 2px;

    &:hover,
    &:focus {
      font-weight:400;
      color: $orange;
      border-bottom:3px solid $orange;
    } 
  }

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    border-bottom-color: #eceaec;

    .accordion-menu a, .dropdown.menu a {
      color: $dark-gray;
      &:hover,
      &:focus {
        font-weight:400;
        color: $orange;
        border-bottom:3px solid $orange;
      } 
    } 
  }

  &.visible {
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom-color: #eceaec;

    .accordion-menu a, .dropdown.menu a {
      color: $dark-gray;
    }
  }

  .title-bar {
    padding: 0px;
    background: transparent;
  }

  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    background: $orange;
    box-shadow: 0 7px 0 $orange, 0 14px 0 $orange;
    content: '';
  }

  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal li {
      margin-left: 1.1em;
    }
  }
}