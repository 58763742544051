@charset 'utf-8';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,400,700,300');

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
//
// @include foundation-xy-grid-classes; 
@include foundation-typography;
@include foundation-button;
@include foundation-forms; 
// @include foundation-range-input; 
@include foundation-accordion; 
@include foundation-accordion-menu;
//@include foundation-badge;
@include foundation-breadcrumbs;  
//@include foundation-button-group;  
@include foundation-callout;
//@include foundation-card; 
@include foundation-close-button;
@include foundation-menu; 
@include foundation-menu-icon;
@include foundation-drilldown-menu; 
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed; 
@include foundation-label;
@include foundation-media-object;
//@include foundation-off-canvas;
@include foundation-orbit;
//@include foundation-pagination; 
//@include foundation-progress-bar; 
//@include foundation-slider; 
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail; 
@include foundation-title-bar; 
//@include foundation-tooltip; 
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

// vendor styles
@import "vendor/aos";
@import "vendor/slick-carousel";
@import "vendor/index-menu";

// mixins
@import 'mixins/container-border';
@import 'mixins/flex';
@import 'mixins/avatar';
@import 'mixins/helpers';
@import 'mixins/psuedo';
@import 'mixins/push-center';
@import 'mixins/sidelines';
@import 'mixins/zindex';

// global styles
@import 'global/page-header';
@import 'global/layout';

//pages
@import 'pages/index';

// custom components
@import 'components/navigation/main-nav';
@import 'components/footer';
