body {
  height: auto !important;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content {
  flex: 1 0;
} 

.centerCol {
    text-align:center;
    @include breakpoint(large) {
      padding-top:rem-calc(40);
      padding-bottom:rem-calc(40);
    }
    padding:rem-calc(20);
  }
  
  h2 {
    text-transform: uppercase;
    font-weight: 600;
  }

  p, h3, h2 {
    text-align: center;
  }

  h3 { 
    font-weight: 600;
  }
  
  .section {
    @include breakpoint(large) {
      padding-top:rem-calc(40);
      padding-bottom:rem-calc(40);
    }
    padding-top:rem-calc(20);
    padding-bottom:rem-calc(20);
  }

  .orangeButton {
    background-color:$orange;
    &:hover {
        background-color: darken($orange,15%);
    }
  }